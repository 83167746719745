<template>
  <container-with-title
    title="优势对比"
    class="advantage-comparison"
    operate-text=""
  >
    <div class="table">
      <div>
        <div>方案</div>
        <div>传输实现方式</div>
        <div>安装方法</div>
        <div>传输延时</div>
        <div>传输质量（丢包率）</div>
        <div>IP地址</div>
        <div>是否会被封IP地址</div>
        <div>是否会被平台限流</div>
      </div>
      <div>
        <div>传统VPN模式</div>
        <div>公共互联网加密</div>
        <div>手机安装客户端软件</div>
        <div>150-2000毫秒（英美）</div>
        <div>2%-20%</div>
        <div>共用IP地址</div>
        <div>概率很高，可更换IP</div>
        <div>概率很高</div>
      </div>
      <div>
        <div>e路播专线</div>
        <div>专用的数据线路</div>
        <div>专线传输设备</div>
        <div>120-180毫秒（英美）</div>
        <div>0.05%-0.1%</div>
        <div>独享IP地址</div>
        <div>否</div>
        <div>否</div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: {
    ContainerWithTitle,
  },
};
</script>

<style lang="scss" scoped>
.advantage-comparison {
  background: none;
  border-radius: 0;
  padding-bottom: 0;
  .table {
    width: 100%;
    display: flex;
    margin: 0 auto;
    & > div {
      width: 33.3%;

      div {
        height: 98px;
        line-height: 98px;
        text-align: center;
        margin-top: -1px;
        margin-left: -1px;
        font-size: 41px;
        color: #39425c;
      }
      &:first-child div {
        background-color: #3661eb;
        border: 1px solid #99bdf4;
        color: #d9e5ff;
        font-size: 41px;
        color: #d9e5ff;

        &:first-child {
          font-size: 51px;
          font-weight: bold;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        & > div {
          color: #39425c;
          border: 1px solid #99bdf4;
          background-color: rgba(255, 255, 255, 0.5);
          &:first-child {
            background-color: #3661eb;
            color: #ffffff;
            font-size: 51px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
