<template>
  <container-with-title title="多场景支持，一站式服务" operate-text="">
    <p class="sub-title">跨境直播，全球平台一键开播</p>
    <img src="@/assets/images/programme/scene-support.png" class="pic" />
    <p class="bottom-text">
      依托e路播全球网络加速专线集群，为Tiktok等跨境直播商家提供安全合规、低延迟、非常稳定、无学习成本的高效直播生产力工具。
    </p>
  </container-with-title>
  <!--<div class="scene-support">-->
  <!--  <p class="title">多场景支持，一站式服务</p>-->
  <!--  <p class="sub-title">跨境直播，全球平台一键开播</p>-->
  <!--  <img src="@/assets/images/programme/scene-support.png" />-->
  <!--  <p class="bottom-text">-->
  <!--    依托e路播全球网络加速专线集群，为Tiktok等跨境直播商家提供安全合规、低延迟、非常稳定、无学习成本的高效直播生产力工具。-->
  <!--  </p>-->
  <!--</div>-->
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
};
</script>

<style lang="less" scoped>
.sub-title {
  font-size: 61px;
  color: #39425c;
  text-align: center;
}

.pic {
  width: 100%;
}

.bottom-text {
  font-size: 61px;
  color: #39425c;
  line-height: 92px;
  text-indent: 2em;
  margin-top: 80px;
  text-align: justify;
}
//.scene-support {
//  height: 758px;
//  background: url("../../../../assets/images/programme/advantage.jpg") center
//    no-repeat;
//  background-size: cover;
//  padding-top: 80px;
//  position: relative;
//
//  .title {
//    color: #39425c;
//    font-size: 44px;
//    font-weight: bold;
//    margin-bottom: 20px;
//    text-align: center;
//  }
//
//  .sub-title {
//    font-size: 18px;
//    color: #39425c;
//    margin-bottom: 44px;
//    text-align: center;
//  }
//
//  img {
//    width: 799px;
//    margin: 0 auto;
//  }
//
//  .bottom-text {
//    width: 100%;
//    color: #39425c;
//    font-size: 18px;
//    position: absolute;
//    text-align: center;
//    margin: 0;
//    bottom: 38px;
//  }
//}
</style>
