<template>
  <container-with-title
    title="e路播专线优势"
    operate-text=""
    class="special-line-advantage"
  >
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/稳定.png" />
        <div>
          <span>稳定网络</span>
          <span>提供急速、好用、安全、稳定、放心的专线出海通道。</span>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/安全.png" />
        <div>
          <span>安全合规</span>
          <span
            >依托国家数据跨境试点，拥有多年互联网安全经验，赋能大数据，完善的网络白名单机制，减少企业内部合规风险，全面尊重客户数据主权，有效保护直播企业出海业务安全。</span
          >
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/使用.png" />
        <div>
          <span>2K超清直播</span>
          <span
            >针对海外直播场景技术优化，提供720P及1080P两种规格的直播加速服务，直播流畅不卡顿。</span
          >
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/IP.png" />
        <div>
          <span>IP稳定</span>
          <span
            >提供固定IP，专号专用，提升涨粉效率，有效保障直播账户安全。</span
          >
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/独享.png" />
        <div>
          <span>独享带宽</span>
          <span
            >每个客户都可以享受独立的带宽，不受外部干扰，在自己的航线中翱翔。</span
          >
        </div>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/全球.png" />
        <div>
          <span>全球覆盖</span>
          <span
            >覆盖全球的数据中心，提供伦敦、马尼拉、东京、胡志明、新加坡、曼谷、华盛顿、迪拜、法兰克福等地域线路供用户选择。</span
          >
        </div>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: {
    ContainerWithTitle,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.special-line-advantage {
  background: none;
  border-radius: 0;
  .list {
    text-align: justify;
    .item {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 51px;
      margin-bottom: 34px;
      @include flex(row, null, center);
      padding: 95px 120px 95px 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        img {
          width: 266px;
          margin-right: 92px;
          margin-left: 123px;
        }
      }
      &:nth-child(2) {
        img {
          width: 435px;
          margin-right: 17px;
          margin-left: 27px;
        }
      }
      &:nth-child(3) {
        img {
          width: 427px;
          margin-right: 19px;
          margin-left: 32px;
        }
      }
      &:nth-child(4) {
        img {
          width: 258px;
          margin-right: 116px;
          margin-left: 108px;
        }
      }
      &:nth-child(5) {
        img {
          width: 270px;
          margin-right: 111px;
          margin-left: 97px;
        }
      }
      &:nth-child(6) {
        img {
          width: 285px;
          margin-right: 101px;
          margin-left: 94px;
        }
      }

      div {
        @include flex(column);
      }

      span {
        &:nth-of-type(1) {
          font-size: 67px;
          font-weight: bold;
          color: #3661eb;
          margin-bottom: 60px;
        }
        &:nth-of-type(2) {
          font-size: 61px;
          color: #39425c;
          line-height: 92px;
        }
      }
    }
  }
}
</style>
