<template>
  <container-with-title
    title="直播加速解决方案"
    operate-text=""
    class="live-acceleration"
  >
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/视频.png" />
        <span>网络卡顿</span>
        <span>优质网络，独享全球专线</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/不安全.png" />
        <span>不安全</span>
        <span>固定IP，保障直播安全和数据流畅</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/一键联网.png" />
        <span>麻烦</span>
        <span>简单方便，无需自己配置</span>
      </div>
    </div>
  </container-with-title>
</template>

<script>
import ContainerWithTitle from "../../../components/ContainerWithTitle";
export default {
  components: {
    ContainerWithTitle,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

.live-acceleration {
  background: none;
  border-right: 0;
  padding-bottom: 0;
  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 17px;

    .item {
      background: rgba(255, 255, 255, 0.5);
      height: 715px;
      border-radius: 51px;
      padding: 0 65px;
      text-align: center;
      @include flex(column, null, center);

      img {
        object-fit: contain;
      }

      &:nth-child(1) {
        padding-top: 111px;
        img {
          width: 205px;
          height: 236px;
        }

        span:first-of-type {
          margin-top: 36px;
          margin-bottom: 34px;
        }
      }

      &:nth-child(2) {
        padding-top: 85px;
        img {
          width: 317px;
          height: 258px;
        }

        span:first-of-type {
          margin-top: 38px;
          margin-bottom: 32px;
        }
      }
      &:nth-child(3) {
        padding-top: 96px;
        img {
          width: 387px;
          height: 292px;
        }

        span:first-of-type {
          margin-bottom: 31px;
        }
      }

      span {
        display: inline-block;
        &:nth-of-type(1) {
          font-size: 67px;
          font-weight: bold;
          color: #3661eb;
        }

        &:nth-of-type(2) {
          font-size: 41px;
          color: #39425c;
          line-height: 1.2;
        }
      }
    }
  }
}
</style>
