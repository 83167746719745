<template>
  <div class="special-line">
    <banner />

    <scene-support class="scene-support" />

    <regional-package :connection-type="connectionType" />

    <live-acceleration />

    <advantage-comparison />

    <special-line-advantage />
  </div>
</template>

<script>
import Banner from "./specialLine/Banner";
import SceneSupport from "./specialLine/SceneSupport";
import RegionalPackage from "./RegionalPackage";
import LiveAcceleration from "./specialLine/LiveAcceleration";
import AdvantageComparison from "./specialLine/AdvantageComparison";
import SpecialLineAdvantage from "./specialLine/SpecialLineAdvantage";
import { ConnectionTypeEnum } from "../../constant/system";

export default {
  components: {
    Banner,
    SceneSupport,
    RegionalPackage,
    LiveAcceleration,
    AdvantageComparison,
    SpecialLineAdvantage,
  },
  data() {
    return {
      connectionType: ConnectionTypeEnum.Direct,
    };
  },
};
</script>

<style lang="less" scoped>
.special-line {
  width: 100%;
  background: #d3e0ff;

  .scene-support {
    margin-top: 50px;
  }
}
</style>
